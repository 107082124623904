import TextField from "@/Components/TextField";
import Button from "@/Components/Button";
import GenericGuestLayout from "@/Layouts/GenericGuestLayout";
import { Strings } from "@/resources";
import AppHead from "@/Components/AppHead";
import { useEffect, useState } from "react";
import { checkEmailMultipleInstance } from "@/services/account";
import { toast } from "react-toastify";
import everflexLogo from "../../../../images/everflex_logo.svg";
import CircularLoader from "@/Components/CircularLoader";
import { determineLoginIdType } from "@/utils/input";

export default function GenericEnterEmail({
    app_version_number,
} : {
    app_version_number?: string;
}) {
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");

    const [inputFormat, setInputFormat] = useState<string>("email");

    const phoneNumberCallback = (value: string) => {
        setEmail("");
        setPhone(value);
        setInputFormat("phone");
    };
    const emailCallback = (value: string) => {
        setEmail(value);
        setPhone("");
        setInputFormat("email");
    };

    const [fullPageLoader, updateFullPageLoader] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [allAccounts, setAllAccounts] = useState<any[]>([]);
    const [isSelectAccountModalVisible, setIsSelectAccountModalVisible] =
        useState<boolean>(false);

    useEffect(() => {
        if (isCleared()) {
            removeVanityUrlFromLocalStorage();
        } else {
            checkVanityUrl();
        }
    }, []);

    const removeVanityUrlFromLocalStorage = () => {
        try {
            localStorage.removeItem("vanityUrl");
        } catch (e) {
            /* empty */
        }
    };

    const storeVanityUrlToLocalStorage = (vanityUrl: string) => {
        localStorage.setItem("vanityUrl", vanityUrl);
    };

    const checkVanityUrl = () => {
        const vanityUrl = localStorage.getItem("vanityUrl");
        if (vanityUrl) {
            updateFullPageLoader(true);
            window.location.replace(
                route("is-logged-in", { clinic: vanityUrl }),
            );
        }
    };

    const isCleared = () => {
        const queryString = window.location.search;
        const searchParams = new URLSearchParams(queryString);
        const clear = searchParams.get("clear");

        if (clear && clear === "true") {
            return true;
        } else {
            return false;
        }
    };

    const submit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        const { error, accounts } = await checkEmailMultipleInstance(
            email,
            phone,
        );
        setIsLoading(false);
        if (error) {
            toast.error(error);
            return;
        } else if (accounts && accounts.length === 0) {
            toast.error(Strings.start_screen.account_not_found);
            return;
        } else if (accounts && accounts.length === 1) {
            accountSelected(accounts[0]);
        } else if (accounts && accounts.length > 1) {
            setAllAccounts(accounts);
            setIsSelectAccountModalVisible(true);
        }
    };

    const accountSelected = (account: any) => {
        if (account.deleted_at) {
            toast.error(Strings.start_screen.account_disabled_error);
            return;
        } else if (account.isActive) {
            storeVanityUrlToLocalStorage(account?.clinic?.vanity_url);
            window.location.replace(
                route("login", {
                    clinic: account?.clinic?.vanity_url,
                    email: account?.email,
                    phone_number: account?.phone_number,
                }),
            );
            return;
        } else if (!account.isActive) {
            window.location.replace(
                route("home", {
                    clinic: account?.clinic?.vanity_url,
                    email: account?.email,
                    phone_number: account?.phone_number,
                    showInviteModal: "true",
                }),
            );
        }
    };

    const renderSelectAccountModal = () => {
        if (!isSelectAccountModalVisible) return null;

        return (
            <div className="flashcard-container show">
                <div className="flash-card clinic-selection-modal">
                    <a
                        href="#"
                        className="close-btn"
                        onClick={() => setIsSelectAccountModalVisible(false)}
                    >
                        {" "}
                    </a>
                    <div className="card-container">
                        <div className="card-content">
                            <h5>{Strings.start_screen.choose_account}</h5>

                            <div className="clinic-selection">
                                {allAccounts?.map(
                                    (account: any, index: number) =>
                                        renderAccount(account, index),
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderAccount = (account: any, index: number) => {
        return (
            <div
                className="clinic-selection-cards"
                onClick={() => accountSelected(account)}
                id={`account-card-${index}`}
            >
                <div className="clinic-img">
                    <img
                        src={
                            account?.clinic?.logo_url
                                ? account?.clinic?.logo_url
                                : everflexLogo
                        }
                        alt="clinic-logo"
                        className="img-fluid"
                    />
                </div>
                <div className="clinic-desc">
                    <h5 className="p-sm">{account?.clinic?.name}</h5>
                    <span>{account?.practice?.name}</span>
                </div>
            </div>
        );
    };

    const userIcon = (
        <span className="svg-img-replacer left-icon">
            <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_1_12122)">
                    <path
                        d="M12 11.1719C14.2091 11.1719 16 9.38101 16 7.17188C16 4.96274 14.2091 3.17188 12 3.17188C9.79086 3.17188 8 4.96274 8 7.17188C8 9.38101 9.79086 11.1719 12 11.1719Z"
                        stroke="var(--secondary-color)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M6 21.1719V19.1719C6 18.111 6.42143 17.0936 7.17157 16.3434C7.92172 15.5933 8.93913 15.1719 10 15.1719H14C15.0609 15.1719 16.0783 15.5933 16.8284 16.3434C17.5786 17.0936 18 18.111 18 19.1719V21.1719"
                        stroke="var(--primary-color)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1_12122">
                        <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(0 0.171875)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </span>
    );

    const renderFullPageLoader = () => {
        return (
            <section className="form-section">
                <div className="container">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <CircularLoader size="large" />
                    </div>
                </div>
            </section>
        );
    };

    const renderVersionNumber = () => {
        return (
            <div className='version-number'>
              <span className='version-number-text'>
                {app_version_number}
              </span>
            </div>
          )
    }

    const renderMainSection = () => {
        return (
            <section className="form-section">
                <div className="container">
                    <form
                        className="form-container enter-email-form"
                        onSubmit={submit}
                    >
                        <div className="form-logo">
                            <img src={everflexLogo} alt="everflex_logo" />
                        </div>
                        <div className="form-content">
                            <div className="form-title">
                                <p className="p-md">
                                    {Strings.start_screen.heading}
                                </p>
                            </div>
                            <div className="input-container">
                                <div className="input-fieldContent">
                                    <TextField
                                        type=""
                                        name="email"
                                        value={
                                            inputFormat === "phone"
                                                ? phone
                                                : email
                                        }
                                        placeholder={
                                            Strings.start_screen
                                                .email_placeholder
                                        }
                                        isFocused={true}
                                        leftIcon={userIcon}
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>,
                                        ) =>
                                            determineLoginIdType(
                                                e.target.value,
                                                phoneNumberCallback,
                                                emailCallback,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="form-btn">
                                <Button
                                    disabled={isLoading}
                                    loading={isLoading}
                                >
                                    <p>{Strings.start_screen.btn_text}</p>
                                </Button>
                            </div>
                            {app_version_number ? renderVersionNumber() : null}
                        </div>
                    </form>
                </div>
            </section>
        );
    };

    return (
        <GenericGuestLayout>
            <main className="signup form-container">
                <AppHead
                    title={
                        fullPageLoader ? "Loading" : Strings.start_screen.title
                    }
                />

                {fullPageLoader ? renderFullPageLoader() : renderMainSection()}
                {renderSelectAccountModal()}
            </main>
        </GenericGuestLayout>
    );
}
