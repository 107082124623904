import { PropsWithChildren } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function GenericGuestLayout({
    children,
}: PropsWithChildren<{ ziggy?: any }>) {
    return (
        <>
            {children}
            <ToastContainer theme="colored" autoClose={7000} />
        </>
    );
}
